<template>
    <div class="ks-page">
        <div class="ks-page-content">
            <div class="ks-logo">
                <div class="d-flex flex-column">
                    <img src="@/assets/logo.png" class="logo"/>
                    <span class="fw-500 text-dark">
                        Program Management Portal
                    </span>
                </div>

                <div class="form-container my-3" autocomplete="off">
                    <div class="form-group">
                        <div class="input-icon icon-left icon-lg icon-color-primary">
                            <input type="text" class="form-control text-uppercase" v-model="data.username" @keypress.enter="$refs.login.$el.click" placeholder="ERM/Mobile Office User ID">
                            <span class="icon-addon">
                                <span class="la la-user"></span>
                            </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-icon icon-left icon-lg icon-color-primary">
                            <input type="password" class="form-control"  v-model="data.password" @keypress.enter="$refs.login.$el.click" placeholder="Password">
                            <span class="icon-addon">
                                <span class="la la-key"></span>
                            </span>
                        </div>
                    </div>
                    
                    <div class="alert" :class="(!message.error ? 'alert-danger' : 'alert-success')" v-if="Object.keys(message).length">
                        {{message.message}}
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="message = {}">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="form-group">
                        <jl-button class="btn btn-primary btn-block" :eventClick="login" ref="login">Login</jl-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="ks-footer my-3">
        <!--  <a href="https://play.google.com/store/apps/details?id=lamco.ardent.mis.pmpwebapp" target="new_tab()" class="btn btn-dark mb-10">
                <img src="assets/img/ps.png" width="20px" class="mr-10" />
                PMP Android
            </a>

            <a href="https://play.google.com/store/apps/details?id=lamco.mis.landaya.miaf" target="new_tab()" class="btn btn-dark mb-10">
                <img src="assets/img/ps.png" width="20px" class="mr-10" />
                MIAF Android
            </a>
            <a href="https://play.google.com/store/apps/details?id=mislamco.louiseandaya.checkrequisition" target="new_tab()" class="btn btn-dark mb-10">
                <img src="assets/img/ps.png" width="20px" class="mr-10" />
                CRF Android
            </a>
            <strong> | </strong>
            <a href="https://testflight.apple.com/join/aIa86NXI" target="new_tab()" class="btn btn-dark mb-10">
                <img src="assets/img/as.png" width="20px" class="mr-10" />
                PMP Apple
            </a>
            <a href="https://testflight.apple.com/join/SBPwgMKm" target="new_tab()" class="btn btn-dark mb-10">
                <img src="assets/img/as.png" width="20px" class="mr-10" />
                MIAF Apple
            </a>
            <a href="https://testflight.apple.com/join/sfE5x67U" target="new_tab()" class="btn btn-dark mb-10">
                <img src="assets/img/as.png" width="20px" class="mr-10" />
                CRF Apple
            </a>
            <br> -->
            <small class="text-muted ks-copyright d-block mt-3">
                <span class="logo-mis align-items-center justify-content-center d-flex"></span>
            </small>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            message: {},
            data: {},
            validate: false
        }
    },
    computed: {
        year() {
            let date = new Date();
            return date.getFullYear();
        }
    },
    methods: {
        async login(e) {
            let res = await this.API.login(this.data);
            if(!res.data) this.message = {message:res};
            this.$jlbutton.unload(e);
        }
    }
}
</script>

<style scoped>
@import url(/assets/styles/pages/auth.min.css);

.logo {
    width: 30%;
}
</style>